import React, {useEffect, useState} from "react";
import User from "../../types/user";
import './navigation.scss';
import ShhIcon from "../shh-icon";

type NavigationProps = {
    user?: User | null;
    children: any;
};


const Navigation = (props: NavigationProps) => {

    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        setLoggedIn(localStorage.getItem('token') !== null);
    }, [])

    return (
        <>
            <nav className={'navigation'}>
                <div className={'navigation-inner'}>
                    <a href={'/'}>
                        <ShhIcon color={'#ffffff'} width={'50px'} height={'50px'}/>
                        <span className={'ms-md-3 md-2 App-highlighted'}>secret</span><span className={'text-white'}>pages</span>
                    </a>
                    <ul>
                        <li>
                            <a href="/">home</a>
                        </li>
                        <li>
                            <a href="/create">create</a>
                        </li>
                        <li>
                            <a href="/publics">publics</a>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className={'content'}>
                {props.children}
            </div>
        </>
    )
}

export default Navigation;
