import {useEffect, useState} from "react";
import {post} from "../api/api";
import {Col, Container, Row} from "react-bootstrap";

const RegisterPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');

    const doRegister = async () => {

        post('https://sp.flwc.cc/register', {
            name: username,
            password: password,
            email: email,
        }).then((response: any) => {
            if (response.status === 200) {
                alert('debug yes')
                window.location.href = '/dashboard';
            }
        })
    }

    return (
        <div>
            <header className="App-header">
                <h1>Register</h1>
                <h2>register to create new secrets</h2>
                <Container>
                    <Row className={'justify-content-center'}>
                        <Col lg={4} className={'mt-5'}>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                doRegister()
                                return false;
                            }}>
                                <input onChange={(e) => {
                                    setUsername(e.target.value)
                                }} type={'text'} placeholder={'Username'}/>
                                <input onChange={(e) => {
                                    setEmail(e.target.value)
                                }} type={'email'} placeholder={'Email'}/>
                                <input onChange={(e) => {
                                    setPassword(e.target.value)
                                }} type={'password'} placeholder={'Password'}/>
                                <button type={'submit'} className={'btn btn-submit mt-3'}>Register</button>
                            </form>
                        </Col>
                    </Row>
                </Container>

            </header>
        </div>
    )
}

export default RegisterPage;
