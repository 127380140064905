import {Dispatch, SetStateAction, useEffect, useState} from "react";
import MDEditor from "@uiw/react-md-editor";
import {Col, Container, FormCheck, Row} from "react-bootstrap";
import '../global.scss';
import {post} from "../api/api";
import Swal from "sweetalert2";
import Turnstile, { useTurnstile } from "react-turnstile";


const CreatePage = () => {
    const [secretId, setSecretId] = useState('');
    const [markdownValue, setMarkdownValue] = useState<string | undefined>("**Hello world!!!**");
    const [title, setTitle] = useState('');
    const [locked, setLocked] = useState(false);
    const [passKey, setPassKey] = useState('');
    const [turnStileToken, setTurnStileToken] = useState<string | undefined>(undefined);

    const onSubmit = (event: any) => {
        event.preventDefault();

        if (locked && passKey === '') {
            Swal.fire({
                title: "oh no",
                text: "failed to create secret, please set a passkey",
                icon: "error",
                customClass: { confirmButton: 'btn btn-submit' }
            });
            return;
        }

        if (!title || title === '') {
            Swal.fire({
                title: "oh no",
                text: "failed to create secret, please set a title",
                icon: "error",
                customClass: { confirmButton: 'btn btn-submit' }
            });
            return;
        }

        post('https://sp.flwc.cc/api/pages', {
            title: title ?? 'untitled secret',
            content: markdownValue,
            view_key: locked ? passKey : '',
            'cf-turnstile-response': turnStileToken
        }).then((response: any) => {
            if (response.status === 200) {
                return response.json()
            }
        }).then((data) => {
            setSecretId(data.data.page_id)
        }).catch(_ => {
            Swal.fire({
                title: "oh no",
                text: "failed to create secret, please try again later",
                icon: "error",
                customClass: { confirmButton: 'btn btn-submit' }
            });
        })
    }

    useEffect(() => {
        if (secretId) {
            window.location.href = `/pages/${secretId}` + (locked ? `?key=${passKey}` : '');
        }
    }, [secretId])

    return (
        <header className="App-header">
            <Container>
                <form className={'mt-md-0 mt-5'} onSubmit={(event) => {
                    onSubmit(event)
                }}>
                    <h1 className={'mb-3 text-center mt-2'}>Create a new secret</h1>
                    <Row className={'mb-3'}>
                        <Col md={6}>
                            <div className={'d-flex align-items-center   '}>
                                <span>public</span>
                                <FormCheck onChange={(e) => setLocked(e.target.checked)} type={'switch'}/>
                                <span>private</span>
                            </div>
                            <input type={'text'} placeholder={'Passkey'}
                                   disabled={!locked}
                                   onChange={(e) => setPassKey(e.target.value)} className={'mt-1 w-50'}/>
                        </Col>
                        <Col className={'mt-md-0 mt-3'} md={{span: 3, offset: 3}}>
                            <input type={'text'} placeholder={'Title'} onChange={(e) => setTitle(e.target.value)} className={'title-input'}/>
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <MDEditor height={350} minHeight={300} value={markdownValue} onChange={(val) => {
                            setMarkdownValue(val)
                        }}/>
                    </Row>
                    <Row className={'justify-content-center'}>
                        {TurnstileWidget(setTurnStileToken)}
                    </Row>
                    <Row className={'justify-content-center mb-4'}>
                        <button type={'submit'} className={'btn ms-auto me-auto w-50 btn-submit mt-5'}>create & view
                        </button>
                    </Row>
                </form>
            </Container>

        </header>
    );
}

function TurnstileWidget(setToken: Dispatch<SetStateAction<string | undefined>>) {
    const turnstile = useTurnstile();
    return (
        <Turnstile
            sitekey="0x4AAAAAAAZom3QxHAhlqEAy"
            onVerify={(token) => {
                setToken(token);
            }}
            className={'d-flex justify-content-center'}
            theme={'dark'}
        />
    );
}

export default CreatePage;
