import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./Home";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import CreatePage from "./pages/create";
import ViewPage from "./pages/view";
import React from "react";
import Navigation from "./components/navigation/navigation";
import Dashboard from "./pages/dashboard/dashboard";

const Root = () => {
    let user = null;

    return (
        <Navigation user={user ?? null}>
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route index element={<Home/>}/>
                        <Route path="login" element={<LoginPage/>}/>
                        <Route path="register" element={<RegisterPage/>}/>
                        <Route path="create" element={<CreatePage/>}/>
                        <Route path="dashboard" element={<Dashboard/>}/>
                        <Route path="pages" element={<ViewPage/>}/>
                        <Route path="publics" element={<ViewPage/>}/>
                        <Route path="pages/:id" element={<ViewPage/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </Navigation>
    )
}

export default Root;
