import {post} from "../api/api";
import {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const doLogin = () => {
        post('https://sp.flwc.cc/login', {email: email, password: password}).then((response: any) => {
            if (response.status === 200) {
                localStorage.setItem('token', response.json().token)
                window.location.href = '/dashboard';
            }
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div>
            <header className="App-header">
                <h1>Login</h1>
                <h2>login to manage your secrets</h2>
                <Container>
                    <Row className={'justify-content-center'}>
                        <Col lg={4} className={'mt-5'}>
                            <form className={'form'} onSubmit={() => doLogin}>
                                <input onChange={(e) => {
                                    setEmail(e.target.value)
                                }} type={'text'} placeholder={'Email'}/>
                                <input onChange={(e) => {
                                    setPassword(e.target.value)
                                }} type={'password'} placeholder={'Password'}/>
                                <button type={'submit'} className={'btn btn-submit mt-3'}>Login</button>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </header>
        </div>
    )
}

export default LoginPage;
