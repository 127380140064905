const get = async <T>(url: string): Promise<any> => {
    const token = localStorage.getItem('token') ?? '';

    const response = await fetch(url, {
        method: 'GET',
        headers: token ? {
            'Content-Type': 'application/json',
            'X-TOKEN': token,
        } : {
            'Content-Type': 'application/json',
        }
    });
    return response as Response;
}

const post = async <T>(url: string, data: any): Promise<Response> => {
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

const auth = async <T>(url: string, token: string): Promise<T> => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    });

    return response.json();
}

export {get, post, auth}
