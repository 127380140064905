import {useEffect, useState} from "react";
import {get} from "../api/api";
import SearchIcon from "./search-icon";
import Markdown from "react-markdown";
import MDEditor from "@uiw/react-md-editor";
import {Container} from "react-bootstrap";
import Swal from "sweetalert2";

type SecretContentProps = {
    id: string;
}

const SecretContent = (props: SecretContentProps) => {
    const [loading, setLoading] = useState(true);
    const [passkeyRequired, setPasskeyRequired] = useState(true);
    const [markdown, setMarkdown] = useState('');
    const [title, setTitle] = useState('');
    const [visibility, setVisibility] = useState('public');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const key = urlParams.get('key');

        if (props.id) {
            get(`https://sp.flwc.cc/api/pages/${props.id}` + (key ? '?view_key=' + key : '')).then((response: any) => {
                return response.json()
            }).then((data) => {
                if (!data.success) {
                    setLoading(false);
                    setPasskeyRequired(true);
                    return;
                }

                setPasskeyRequired(false);

                setTitle(data.data.title);
                setMarkdown(data.data.content);
                setLoading(false);
                setVisibility(key ? 'private' : 'public');
            }).catch(_ => {
                Swal.fire({
                    title: "oh no",
                    text: "failed to load page, please try again later",
                    icon: "error",
                    customClass: { confirmButton: 'btn btn-submit' }
                });
            })
        }
    }, [props.id])

    return (
        <header className="App-header">
            {loading ?
                <>
                    <SearchIcon color={'#ffffff'} width={'150px'} height={'50%'}/>
                    <h2 className={'mt-3'}>{'loading secret...'
                    }</h2>
                </> : passkeyRequired ?
                    <>
                        <h1>passkey required</h1>
                        <p>YOU SHALL NOT PASSkey!</p>
                    </> : <>
                        <h1>{title}</h1>
                        <p>visibility: <i>{visibility}</i></p>
                        <div className={'markdown-holder mt-3'}>
                            <MDEditor preview={'preview'} value={markdown} hideToolbar={true} className={''}/>
                        </div>
                    </>}
        </header>
    );
}

export default SecretContent;
