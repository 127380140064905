import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useParams} from "react-router";
import SecretContent from "../components/secret-content";
import {get} from "../api/api";
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import SearchIcon from "../components/search-icon";
import Swal from "sweetalert2";

type PageResult = {
    items: any[];
    per_page: number;
    current_Page: number;
    total: number;
    last_page: number;
}

const ViewPage = () => {
    const {id} = useParams();
    const [pages, setPages] = useState({items: [], current_Page: 1, per_page: 10, last_page: 1, total: 0} as PageResult);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!id) {
            setLoading(true);

            get('https://sp.flwc.cc/api/pages?page=' + page).then((response: any) => {
                return response.json()
            }).then((data) => {
                const result = data.data as PageResult;

                setPages(result);
                setLoading(false);
            }).catch(_ => {
                Swal.fire({
                    title: "oh no",
                    text: "failed to load public, please try again later",
                    icon: "error",
                    customClass: { confirmButton: 'btn btn-submit' }
                });
            })
        }
    }, [page])

    const formatDate = (date: string) => {
        return new Date(date).toLocaleDateString('de-AT', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        });
    }

    return (
        <header className="App-header">
            {!id ?
                <Container style={{minHeight: 'calc(100vh - 110px)'}}> {/* TODO: fix */}
                    <div className={'mb-4'}>
                        {ButtonRow(page, setPage, pages.last_page)}
                    </div>
                    <Row>
                        {loading ?
                        <>
                            <div className={"pt-4"} style={{width: '200px', marginInline: 'auto'}}>
                                <SearchIcon color={'#ffffff'} width={'150px'} height={'50%'}/>
                                <h2 className={'mt-3'}>{'loading publics...'
                                }</h2>
                            </div>
                        </> :
                            <>
                                <Col lg={12} md={12} sm={12} key={-1} className={'border-bottom d-flex justify-content-between'}>
                                <span>title</span>
                                <div><span>created</span><span className={'ms-4'}>updated</span></div>
                            </Col>
                            {pages.items.map((secret: any) => {
                            return (
                                <Col lg={12} md={12} sm={12} key={secret.id}
                                     className={'border-bottom d-flex justify-content-between mt-3'}>
                                    <small> <a className={'decoration-none App-highlighted'}
                                               href={`/pages/${secret.id}`}><FontAwesomeIcon
                                        className={'me-2'}
                                        icon={faLink}/>{secret.title}</a></small>
                                    <span>
                                        <small>{formatDate(secret.created_at)}</small>
                                        <small className={'ms-4'}>{formatDate(secret.updated_at)}</small>
                                    </span>
                                </Col>
                            )
                        })}
                        <div className={'mb-2'}>
                            {ButtonRow(page, setPage, pages.last_page)}
                        </div>
                        </>}
                    </Row>
                </Container> :
                <SecretContent id={id}/>
            }
        </header>
    );
}

const ButtonRow = (page: number, setPage: Dispatch<SetStateAction<number>>, last_page: number) => {
    return <>
        <div className={'d-flex flex-row gap-4 align-items-baseline'}>
            <button className={'btn ms-auto me-auto w-50 btn-submit mt-5'} onClick={() => setPage(1)} disabled={page <= 1}>start</button>
            <button className={'btn ms-auto me-auto w-50 btn-submit mt-5'} onClick={() => setPage(page - 1)} disabled={page <= 1}>previous</button>
            <span>{page}&nbsp;/&nbsp;{last_page}</span>
            <button className={'btn ms-auto me-auto w-50 btn-submit mt-5'} onClick={() => setPage(page + 1)} disabled={page >= last_page}>next</button>
            <button className={'btn ms-auto me-auto w-50 btn-submit mt-5'} onClick={() => setPage(last_page)} disabled={page >= last_page}>end</button>
        </div>
    </>
}

export default ViewPage;
