import React, {useEffect, useState} from 'react';
import './dashboard.scss';
import {get} from "../../api/api";
import {Secret, SecretResponse} from "../../api/types";
import Swal from "sweetalert2";

const Dashboard = () => {
    const [secrets, setSecrets] = useState<Secret[]>([]);

    useEffect( () => {
        const response = get('https://sp.flwc.cc/secrets');
        response.then((response: SecretResponse) => {
            setSecrets(response.pages);
        }).catch(_ => {
            Swal.fire({
                title: "oh no",
                text: "failed to load secrets, please try again later",
                icon: "error",
                customClass: { confirmButton: 'btn btn-submit' }
            });
        })
    }, [])

    return (
        <>
            <header className="App-header">
                <h1>Dashboard</h1>
                <h2>view your secrets</h2>
                <div className={'secrets'}>
                    {secrets.length > 0 ? secrets.map((secret: any) => {
                        return (
                            <div className={'secret'}>
                                <h3>{secret.title}</h3>
                                <p>{secret.content}</p>
                            </div>
                        )
                    }) : <p className={'mt-5'}>No secrets found :(</p>}
                </div>
            </header>
        </>
    );
}

export default Dashboard;
