import React from 'react';
import './global.scss';
import ShhIcon from "./components/shh-icon";

function Home() {
    return (
        <div>
            <header className="App-header">
                <h1 className={'mb-3'}><span className={'App-highlighted'}>secret</span>pages</h1>
                <ShhIcon color={'#ffffff'} width={'200px'} height={'50%'}/>
                <h2>
                    Shh... we'll keep it a secret.
                </h2>
                <p>Write down your secret and share to anyone. In secret.</p>
                <div className={'pt-5'}>
                    <a
                        className="App-link me-5"
                        href="/create"
                    >
                        create
                    </a>
                    <a
                        className="App-link"
                        href="/publics"
                    >
                        publics
                    </a>
                </div>
            </header>
        </div>
    );
}

export default Home;
